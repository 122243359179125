import React from "react";
import { generate } from "short-uuid";
import getAppTenantIdTsg from "./utils/get-app-tenant-id-tsg";
import getAppTenantId from "./utils/get-app-tenant-id";
/* Warning: please do not statically import any dependencies here except for static assets like icons */

const init = ({ vars, onAuthed, sparkyFramework }) => {
    onAuthed((params) => {
        const setReportUuids = require("./utils/set-report-uuids").default;
        const reports = tryParse(process.env.REACT_APP_REPORTS, []);

        for (const report of reports) {
            const uuid = new URLSearchParams(window.location.search).get("report-uuid") || generate();

            setReportUuids(report.id, uuid);
        }

        params.getFeatures();
        params.getLicenseInfo();
        params.getFqdnInfo();

        return Promise
            .all([sparkyFramework.loaded, params.getAuthInfo()])
            .then(() => import("@reporting-n-viz/vnr-entrypoint/dist/api-prefetch-fawkes"))
            .then(({ prefetch }) => {
                const getCsIdTsg = require("./utils/get-cs-id-tsg").default;
                const getCsId = require("./utils/get-cs-id").default;
                const getLsTenantIdTsg = require("./utils/get-ls-tenant-id-tsg").default;
                const getLsTenantId = require("./utils/get-ls-tenant-id").default;
                const hasTsgSupport = sparkyFramework.hasTsgSupport();
                const main = sparkyFramework.getMainState() || {};
                const authData = hasTsgSupport ? sparkyFramework.getAuthState() : sparkyFramework.getAuthData(main.tenant, main.authInfo, main.loggedInUser);
                const managedAppName = sparkyFramework.StartupManager.getManagedAppName();
                const appTenantId = hasTsgSupport ? getAppTenantIdTsg(authData, managedAppName) : getAppTenantId(main);

                return prefetch(
                    vars.api_server_url,
                    {
                        tokenManager: hasTsgSupport ? null : sparkyFramework.getTokenManager(main.tenant, main.authInfo, main.loggedInUser),
                        csId: hasTsgSupport ? getCsIdTsg(authData, managedAppName) : getCsId(main),
                        appsToTenantMap: new Map([[managedAppName, new Set([appTenantId])]]),
                        hasTsgSupport,
                        getCurrentTsgToken: sparkyFramework.getCurrentTsgToken,
                        managedAppName,
                        appTenantId,
                        authData,
                        loggedInUser: main.loggedInUser,
                        appTenantRegion: main.tenant.info.region,
                        isEarlyAccessNgfwTenant: main.tenant.is_early_access_ngfw_tenant,
                        lsTenantId: hasTsgSupport ? getLsTenantIdTsg(authData) : getLsTenantId(main)
                    },
                    sparkyFramework.actions.setValues
                );
            });
    });

    return import(/* webpackPreload: true */ './routes').then(r => r.default)
};

init.exposes = {
    LogViewerComponent: () => import("./LogViewer"),
    ExploreAppProvider: () => import("./Explore")
};

export default init;

function tryParse(raw, defaultValue) {
    try {
        return JSON.parse(raw) || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}
